import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { RequestTrailConfirmAPI, RequestTrial, User } from '../model/authentication-model';
import { Router } from '@angular/router';

@Injectable({
     providedIn: 'root'
})

export class AuthenticationService {
     token: string;
     short_name: string;
     orgId: string;
     org_name: string;
     user_id: string;
     errors = [];
     token_expires;
     username: string;
     private _user = new BehaviorSubject<User>(null);
     AWSurl = 'https://dev.usermanagement.gva.semgenome.com';

     constructor(private http: HttpClient, private router: Router) { }

     get userIsAuthenticated(): Observable<any> {
          return this._user.asObservable().pipe(
               map((user) => {
                    if (user) {
                         return !!user.token;
                    } else {
                         false;
                    }
               }
               )
          );
     }

     onLogin(username: string, password: string) {
          const result = this.login('api-token-auth/', {
               username: username,
               password: password,
          })
               .pipe(
                    tap((userData) => {
                         this.updateData(userData['token'], userData['short_name'], userData['org_id'], userData['user_id'],userData['org_name']);
                         this._user.next(
                              new User(this.username, this.token, this.token_expires, this.short_name, this.orgId, this.user_id, this.org_name)
                         );
                         this.storeAuthData(this.username, this.token, this.token_expires, this.short_name, this.orgId, this.user_id, this.org_name);
                    })
               );
          return result;
     }

     login(endpoint: string, body?: any, headers?: HttpHeaders): Observable<any> {
          var url = environment.user + endpoint;
          if (!headers) {
               var headers = new HttpHeaders();
               headers = headers.append('Content-Type', 'application/json');
          }
          return this.http.post(url, body, { headers: headers }).pipe(tap((data) => { },
               (error) => {

               }));
     }

     public updateData(token, short_name, org_id, user_id, org_name) {
          this.token = token;
          this.short_name = short_name;
          this.orgId = org_id;
          this.org_name = org_name;
          this.user_id = user_id;
          this.errors = [];
          const token_parts = this.token.split(/\./);
          const token_decoded = JSON.parse(window.atob(token_parts[1]));
          this.token_expires = new Date(token_decoded.exp * 1000);
          this.username = token_decoded.username;
     }

     public storeAuthData(username: string, token: string, expiry: string, short_name: string, orgId: string, user_id: string, org_name: string) {
          const userData = {
               username: username,
               token: token,
               expirationDate: expiry,
               short_name: short_name,
               org_id: orgId,
               user_id: user_id,
               org_name: org_name
          };
          localStorage.setItem('user', JSON.stringify(userData));
     }

     autoLogin() {
          if (!localStorage.getItem('user')) {
               return of(null);
          }
          const userData = JSON.parse(localStorage.getItem('user'));
          const expirationDate = new Date(userData.expirationDate);
          if (expirationDate <= new Date()) {
               return of(null);
          } else {
               return of(true);
          }
     }

     signout(headers): Observable<any>  {
          localStorage.removeItem('user');
          localStorage.removeItem('userdata');
          localStorage.removeItem('caseData');
          localStorage.removeItem('Profile');
          this._user.next(null);
          this.router.navigate(['/']);
          const url = environment.user + 'sign_out/';
          return this.http.post(url, '' ,{headers: headers}).pipe(tap((data)=>{
          }, (error)=>{
          }));
     }
     
     resetPassword(params: any): Observable<any> {
          var url = environment.user + 'forgot_password/';
          return this.http.get(url, params).pipe(tap((data) => {

          }, (error) => {

          }));
     }

     updatePassword(params: any): Observable<any> {
          var url = environment.user + 'update_password/';
          return this.http.post(url, params).pipe(tap((data) => {

          }, (error) => {

          }));
     }

     postRequestForTrial(body: RequestTrial ): Observable<string> {
          var headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          return this.http.post(environment.user+'requestfortrial/', body, {headers: headers})
          .pipe(tap((data: string) => {
               
          }, (error) => {
             
          }));
     }

     getRequestForTrial(email: string): Observable<RequestTrailConfirmAPI> {
          return this.http.get(environment.user+'activate/'+email)
          .pipe(tap((data:RequestTrailConfirmAPI) => {
          },
          (error) => {
            
          }));
     }

     activateRequestForTrial(body: any): Observable<string> {
          var headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'application/json');
          return this.http.post(environment.user+'password/', body, {headers: headers})
          .pipe(tap((data:string) => {               
          }, (error) => {

          }));
     }

}
