import { Action } from "@ngrx/store";
import { MenuCondition } from "./menu.action";

const initialState = true

export function menuReducer(state = initialState, action: MenuCondition) {
  switch (action.type) {
    case 'MENU_CONDITION':
      return action.payload;
    default:
      return state;
  }
}
