<!-- <div class="nav-bar">
  <img src="../assets/logos/gva-logo.png" class="logo">
</div> -->
<div class="main">
  <router-outlet></router-outlet>
</div>
<div class="phone1">
  <!-- <h2>Please switch to desktop view</h2> -->
</div>
<div class="container-fluid" *ngIf="url != '/'">
     <div class="row">
          <div class="col-md-12 footer">
               &copy; {{currentYear}} Semantic Web India Pvt Ltd.
          </div>
     </div>
</div>
