import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
     selector: 'app-snack-bar-danger',
     templateUrl: './snack-bar-danger.component.html',
     styleUrls: ['./snack-bar-danger.component.scss']
})

export class SnackBarDangerComponent implements OnInit {

     constructor(
          @Inject(MAT_SNACK_BAR_DATA) public data,
          private _snackRef: MatSnackBarRef<SnackBarDangerComponent>,
          private ren: Renderer2
     ) {
          setTimeout(()=>{
               let snackEl = document.getElementsByClassName('closeSnackBar').item(0);
               ren.listen(snackEl, 'click', ()=>this.dismiss())
          })
     }

     ngOnInit(): void {}

     dismiss(){
          this._snackRef.dismiss();
     }

}
