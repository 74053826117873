import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
     selector: 'app-root',
     templateUrl: './app.component.html',
     styleUrls: ['./app.component.scss']
})

export class AppComponent {
     title = 'GVA-UI-NEW';
     currentYear = (new Date()).getFullYear();
     url:string = '';
     constructor(private router: Router ) {}
     ngOnInit(): void {
          this.router.events.subscribe((event) => {
               this.url = this.router.url;
          });
     }
}
