<div class="snackBarcontainer">
     <div class="icon">
          <img src="assets/home/icons/info.svg" alt="Information">
     </div>
     <div class="content">
          {{data?.msg}}
     </div>
     <div class="closeSnackBar" (click)="dismiss()">
          <img src="assets/home/icons/close.svg" alt="Close Snack Bar">
     </div>
</div>
